<template>
  <div class="container custom-container">
    <header >
      <div class="container">
        <nav>
          <a href="#" class="nav-brand">Wallop Labs LLC</a>
          <div class="nav-links">
            <a href="#about">About</a>
            <a href="#services">Services</a>
            <a href="#approach">Approach</a>
          </div>
        </nav>
      </div>
    </header>

    <div class="hero-section">
      <div class="hero-content">
        <div class="hero-logo">
          <img src="/assets/samples/logo_2.png" alt="Wallop Labs Logo" class="logo-image" />
        </div>
        <div class="hero-text">
          <h1>Wallop Labs LLC</h1>
          <p class="lead">
            Digital Product Management and Technology Consulting
          </p>
        </div>
      </div>
    </div>

    <main>
      <section id="about" class="content-section">
        <div class="container">
          <h2>About Wallop Labs</h2>
          <p>
            Wallop Labs LLC is a product management and technology consulting firm. With over 15 years of experience and a portfolio of 80+ projects, we specialize in delivering innovative solutions across a broad range of industries, including web, mobile, social, geo, live streaming video, IoT/hardware devices, Web3/blockchain, and applied AI.
          </p>
        </div>
      </section>

      <section id="services" class="content-section">
        <div class="container">
          <h2>Services & Areas of Expertise</h2>
          <p style="text-align: center;">Wallop Labs offers a range of services tailored to meet the unique needs of our clients. </p>
          
          <div class="service-list">
            <div v-for="(service, index) in services" :key="index" class="service-item">
              <h4>{{ service.title }}</h4>
              <ul>
                <li v-for="(point, pointIndex) in service.points" :key="pointIndex">
                  {{ point }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="approach" class="content-section">
        <div class="container">
          <h2>Our Approach</h2>
          <p>
            Wallop Labs blends a rare trove of broad and deep experience with industry-leading best practices, including Blue Ocean Strategy, Silicon Valley Product Group, Google Ventures Design Sprints, Amazon Workbacks, and Agile methodologies. Our methods are tailored to suit different stages of product maturity, from finding product-market fit to scaling and exploring adjacent innovations.
          </p>
        </div>
      </section>
    </main>

    <footer>
      <p>&copy; 2025 Wallop Labs LLC. 4400 S. Monaco St., Denver, Colorado. USA.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  setup() {
    const services = [
      {
        title: 'Applied AI Discovery & MVP Prototyping',
        points: [
          'Easy-to-implement AI solutions that drive business outcomes.',
          'Explore client insights, validate ideas, and swiftly deploy prototypes and MVPs.',
          'Pioneering experience in applied AI solutions.'
        ]
      },
      {
        title: 'Generative Research Strategy & Interviews',
        points: [
          'Identify hidden customer opportunities and validate product hypotheses.',
          'Develop customized research and interview guides.',
          'Align with frameworks like The Mom Test and Continuous Discovery Habits.'
        ]
      },
      {
        title: 'Business Analysis',
        points: [
          'Comprehensive requirements gathering and modeling for large-scale projects.',
          'Expertise in UML analysis and adaptable documentation.'
        ]
      },
      {
        title: 'Product Analytics & Product Data Science',
        points: [
          'Deep analysis of product usage, identifying user drop-off points, and interpreting A/B testing data.',
          'Forensic analysis of user journeys and data science insights to drive growth.'
        ]
      },
      {
        title: 'Willingness-to-Pay Studies & eCommerce Modeling',
        points: [
          'Conduct studies to determine customers willingness to pay.',
          'Shape product roadmaps, partnerships, and revenue strategies.',
          'Apply Bayesian probability modeling for reliable purchase rate predictions.'
        ]
      },
      {
        title: 'Competition & Whitespace Strategy Workshops',
        points: [
          'Identify new opportunities and differentiate your products in crowded markets.',
          'Facilitate practical processes including Strategy Canvas and Customer Journey Utility Map.'
        ]
      },
      {
        title: 'Design Discovery Workshops',
        points: [
          'Unblock problem identification and produce validated product concepts.',
          'Available in 1-day, 3-day, and 5-day versions to suit your needs.'
        ]
      },
      {
        title: 'GTM Workbacks aka Business Plans',
        points: [
          'Create customer-centric business plans that work backwards from customer satisfaction.',
          'Align product definitions with stakeholder expectations and market needs.'
        ]
      },
      {
        title: 'Omnichannel User Journey Mapping & Funnels Analysis',
        points: [
          'Comprehensive mapping of full-funnel user journeys to identify opportunities for improvement.',
          'Integrate omnichannel data for a unified view of customer behavior.'
        ]
      },
      {
        title: 'Digital Product Growth Optimization',
        points: [
          'Transform your products and services into self-sustaining growth engines.',
          'Integrate feedback loops that drive meaningful outcomes and sustainable growth.'
        ]
      },
      {
        title: 'BI Analysis & Dashboarding',
        points: [
          'Define essential KPIs and create dashboards using platforms like Tableau and Metabase.',
          'Deliver refined insights based on your data warehouse specifics.'
        ]
      },
      {
        title: 'Agile Coaching & Scrum Management',
        points: [
          'Coaching on Agile practices and managing software delivery teams for consistent quality and performance.',
          'Guide teams through group development stages to high performance.'
        ]
      },
      {
        title: 'Software Delivery Team Troubleshooting',
        points: [
          'Evaluate team structure, processes, and skills to identify and address upstream issues.',
          'Create aligned action plans to improve execution.'
        ]
      },
      {
        title: 'Group Decision-Making Facilitation',
        points: [
          'Facilitate effective group decision-making using frameworks like SPADE.',
          'Ensure alignment and clarity in decision-making processes.'
        ]
      },
      {
        title: 'Product Manager Coaching',
        points: [
          'Coaching for junior product managers to help them master their trade.',
          'Build cross-disciplinary experience on the job.'
        ]
      },
      {
        title: 'Internal Product Accelerator Program',
        points: [
          'Foster innovation within your company and launch new products.',
          'Initiate manageable projects and achieve product/market fit through public iterations.'
        ]
      }
    ];

    return {
      services
    };
  }
}
</script>

<style>
/* Modern CSS Reset and Base Styles */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', system-ui, -apple-system, sans-serif;
  color: #2D3748;
  background-color: #F7FAFC;
  line-height: 1.6;
  scroll-behavior: smooth;
}

/* Header Improvements */
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  z-index: 1000;
  padding: 1rem 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.sticky-header .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

nav {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-brand {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.logo-explosion {
  font-size: 2rem;
  font-weight: 900;
  color: #2B6CB0;
  background: linear-gradient(135deg, #2B6CB0, #4299E1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
  animation: pulse 2s infinite;
}

.logo-explosion::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120%;
  height: 120%;
  background: radial-gradient(circle, rgba(66,153,225,0.15) 0%, rgba(66,153,225,0) 70%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -1;
  animation: explode 2s infinite;
}

.brand-text {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2B6CB0;
  margin-left: 0.25rem;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes explode {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

.nav-links {
  display: flex;
  gap: 2rem;
}

nav a {
  text-decoration: none;
  color: #4A5568;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.2s ease;
  position: relative;
}

nav a:hover {
  color: #2B6CB0;
}

nav a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #2B6CB0;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

nav a:hover::after {
  transform: scaleX(1);
}

/* Hero Section Refinements */
.hero-section {
  padding: 8rem 2rem 6rem;
  background: linear-gradient(135deg, #2B6CB0, #4299E1);
  color: white;
  overflow: hidden;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.hero-logo {
  flex-shrink: 0;
  animation: floatAnimation 6s ease-in-out infinite;
}

.logo-image {
  width: 256px;
  height: 256px;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

.hero-text {
  text-align: left;
}

.hero-section h1 {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  letter-spacing: -0.025em;
}

.hero-section .lead {
  font-size: 1.5rem;
  font-weight: 400;
  max-width: 600px;
  opacity: 0.9;
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 1200px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }
  
  .hero-text {
    text-align: center;
  }
  
  .logo-image {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 768px) {
  .logo-image {
    width: 300px;
    height: 300px;
  }
}

/* Content Sections Improvements */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.content-section {
  padding: 4rem 0;
  background-color: white;
  margin: 2rem 0;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

h2 {
  color: #2D3748;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 700;
}

.service-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.service-item {
  background-color: #F7FAFC;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.service-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.service-item h4 {
  color: #2B6CB0;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.service-item ul {
  padding-left: 1.5rem;
  list-style-type: none;
}

.service-item ul li {
  margin-bottom: 0.75rem;
  position: relative;
}

.service-item ul li::before {
  content: "•";
  color: #4299E1;
  font-weight: bold;
  position: absolute;
  left: -1.25rem;
}

/* Footer Refinements */
footer {
  padding: 2rem;
  background-color: #2D3748;
  color: #E2E8F0;
  text-align: center;
}

footer p {
  max-width: 600px;
  margin: 0 auto;
  font-size: 0.875rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2.5rem;
  }
  
  .hero-section .lead {
    font-size: 1.25rem;
  }
  
  .nav-links {
    gap: 1rem;
  }
  
  .service-list {
    grid-template-columns: 1fr;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.content-section {
  animation: fadeIn 0.6s ease-out;
}
</style>